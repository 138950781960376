import { tryCatch } from '@thalesrc/js-utils';

import http from './http';
import { USER_URL } from '../utils/url';

async function list(payload) {
  const [error, result] = await tryCatch(http.post(`${USER_URL}/search`, payload));

  if (error) throw error;

  return result.data.data;
}
async function detail(id) {
  const [error, result] = await tryCatch(http.get(`${USER_URL}/${id}`));

  if (error) throw error;

  return result.data.data;
}

async function add(payload) {
  const [error, result] = await tryCatch(http.post(USER_URL, payload));

  if (error) throw error;

  return result.data.data;
}

async function update(payload) {
  const [error, result] = await tryCatch(http.put(USER_URL, payload));

  if (error) throw error;

  return result.data.data;
}

async function updateUserLanguage(payload) {
  const [error, result] = await tryCatch(http.put(`${USER_URL}/language`, payload));

  if (error) throw error;

  return result.data.data;
}

async function getAllCounsellor() {
  const [error, result] = await tryCatch(http.get(`${USER_URL}/counsellor-users`));

  if (error) throw error;

  return result.data.data;
}

async function getAllUsers() {
  const [error, result] = await tryCatch(http.get(`${USER_URL}/users`));

  if (error) throw error;

  return result.data.data;
}

async function getHeadAndManagerUsers() {
  const url = `${USER_URL}/head-manager-users`;

  const [error, result] = await tryCatch(http.get(url));

  if (error) throw error;

  return result.data.data;
}

export const userService = {
  detail,
  add,
  update,
  list,
  getAllCounsellor,
  updateUserLanguage,
  getAllUsers,
  getHeadAndManagerUsers
};
